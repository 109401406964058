<script>
export default {
	props: ['currentZone', 'ProductPage'],
	hookZone: ['after--images', 'bottom-inside'],
	showHook(currentZone, ProductPage) {
		return ProductPage.product.info.templateKey == 'infoAccordions'
	},
	data() {
		return {
			panels: [],
		}
	},
	created() {
		this.templateData.items.forEach((item, n) => {
			this.panels.push(n)
		})
	},
	computed: {
		show() {
			if (!this.templateData.items?.length) return false
			if (this.$b.d) return this.currentZone == 'after--images'
			return this.currentZone == 'bottom-inside'
		},
		templateData() {
			return this.ProductPage.product.info.templateData
		},
		attrs() {
			return this.ProductPage.product.attrs || []
		},
	},
}
</script>

<template>
	<div v-if="show" class="info-accordions" :class="$b.m && '--mobile'">
		<v-expansion-panels v-model="panels" multiple flat>
			<v-expansion-panel v-for="(item, n) in templateData.items" :key="n" class="base">
				<v-expansion-panel-header class="font-4" :class="$b.mt && 'px-0'" hide-actions>
					<template #default="{ open }">
						<div
							style="border-bottom: 1px solid var(--secondary)"
							class="d-flex justify-space-between align-center"
						>
							<div class="header is-heading">{{ item.title }}</div>
							<v-icon :color="$vars.secondarytext">{{
								open ? 'mdi-chevron-up' : 'mdi-chevron-down'
							}}</v-icon>
						</div>
					</template>
				</v-expansion-panel-header>
				<v-expansion-panel-content :class="$b.mt && 'px-0'">
					<div v-for="(content, n) in item.content" :key="n">
						<ProductPage-Attributes
							class="py-2"
							v-if="content.type == 'attrs'"
							:attributes="attrs"
							:show-title="false"
						/>
						<div class="py-2" v-if="content.type == 'text'" v-html="content.text"></div>
						<Media
							class="py-2"
							v-if="content.type == 'img'"
							:src="$b.m ? content.imgMobile : content.img"
							width="100%"
						/>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</div>
</template>

<style scoped lang="scss">
.info-accordions.--mobile ::v-deep {
	.v-expansion-panel-content__wrap {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}
</style>

